import React, { useEffect, useState, useCallback } from "react"
import { graphql, Link } from "gatsby"

import Layout from '../components/layout'

import '@wordpress/block-library/build-style/style.css'
import ImageViewer from "react-simple-image-viewer";

function CaseStudyPageTemplate(props) {
    let data = props.data;

    const [currentImage, setCurrentImage] = useState(0);
    const [isViewerOpen, setIsViewerOpen] = useState(false);
    const [images, setImages] = useState([])

    const [customOpener, setCustomOpener] = useState("");

    const openImageViewer = useCallback(index => {
        setCurrentImage(index);
        setIsViewerOpen(true);
    }, []);

    const closeImageViewer = () => {
        setCurrentImage(0);
        setIsViewerOpen(false);
    };


    useEffect(() => {
        const isBrowser = typeof window !== "undefined";


        if (isBrowser) {
            if (data.wpCaseStudy.extraGallery?.gallery && data.wpCaseStudy.extraGallery?.gallery.length == 0) {
                var elements = document.getElementsByClassName("blocks-gallery-item");

                var myFunction = function (e) {

                    if (e.target.src) {
                        setCustomOpener(e.target.src)
                    } else {
                        console.log("NO SOURCE")
                    }
                };

                let tmpGallery = [];
                for (var i = 0; i < elements.length; i++) {

                    elements[i].addEventListener('click', myFunction, false);

                    if (elements[i]?.children[0]?.children[0]?.children[0]?.children[2]?.children[1]?.src) {
                        tmpGallery.push(elements[i]?.children[0]?.children[0]?.children[0]?.children[2]?.children[1]?.src)
                    }
                }

                console.log(tmpGallery)
                setImages(tmpGallery);
            }else{
                let tmpGallery = [];

                if(data.wpCaseStudy.extraGallery?.gallery){
                    data.wpCaseStudy.extraGallery?.gallery.map((img3) => {
                        tmpGallery.push(img3.image.sourceUrl)
                    })
                }
                setImages(tmpGallery);
            }
        }
    }, [])

    useEffect(() => {
        if (customOpener !== "") {
            openImageViewer(images.indexOf(customOpener))
        }
    }, [customOpener])


    // console.log(data)


    function constructMetaData(page, currentPage, language) {
        let payload = {
            title: page.seo.title,
            description: page.seo.metaDesc,
            canonical: `https://trustdev.gr${currentPage}`,
            fbTitle: page.seo.opengraphTitle ? page.seo.opengraphTitle : page.seo.title,
            fbDescription: page.seo.opengraphDescription ? page.seo.opengraphDescription : page.seo.metaDesc,
            fbUrl: `https://trustdev.gr${currentPage}`,
            fbType: "website",
            locale: language.toLowerCase(),
            image: page.seo.opengraphImage ? page.seo.opengraphImage.sourceUrl : ""
        }
        return payload
    }


    return (
        <Layout
            headerMenu={props.pageContext.headerMenu}
            footerMenu={props.pageContext.footerMenu}
            currentPage={{ currentPage: props.pageContext.currentPage, language: props.pageContext.languageName }}
            availableVersions={props.pageContext.availablePages}
            globalInfo={data.wpTemplate.globalInformation}
            breadcrumbs={data.wpCaseStudy.seo.breadcrumbs}

            locale={props.pageContext.language}
            metaData={constructMetaData(data.wpCaseStudy, props.pageContext.currentPage, props.pageContext.language)}
        // services={props.pageContext.servicesCategories}
        >
            <div>
                {/* <section id="firstSection">
                    <ServiceContent
                        data={data.wpCaseStudy}
                    />
                </section> */}
                <section>
                    <div className="container-cs mx-auto py-16 md:py-32 px-4">
                        <h1 className="service-h1">{data.wpCaseStudy.title}</h1>

                        <div className="service-editor" dangerouslySetInnerHTML={{ __html: data.wpCaseStudy.content }} />

                        {data.wpCaseStudy.extraGallery?.gallery &&data.wpCaseStudy.extraGallery?.gallery.length > 0 && (
                            <ul className="flex flex-wrap pt-4 pb-8">
                                {data.wpCaseStudy.extraGallery?.gallery.map((img2, i) => (
                                    <li className="w-full md:w-1/2 lg:w-1/3 pr-4 py-2" key={`img-gallery-key-${i}`}>
                                        {img2?.image?.sourceUrl && (
                                            <img
                                                onClick={(e) => setCustomOpener(e.target.src)}
                                                src={img2.image.sourceUrl}
                                                alt={img2.image.altText}
                                                style={{ borderRadius: "5px", cursor: "zoom-in" }}
                                            />
                                        )}

                                    </li>
                                ))}
                            </ul>
                        )}
                    </div>
                </section>

            </div>

            {isViewerOpen && (
                <ImageViewer
                    src={images}
                    currentIndex={currentImage}
                    onClose={closeImageViewer}
                    backgroundStyle={{
                        backgroundColor: "rgba(0,0,0,0.9)"
                    }}
                />
            )}
        </Layout>
    )
}

export default CaseStudyPageTemplate

export const pageQuery = graphql`query GET_CASE_STUDY_PAGE($id: String, $templateId: String) {
    wpCaseStudy(id: { eq: $id}){
        seo {
            canonical
            title
            metaDesc
            opengraphDescription
            opengraphTitle
            opengraphImage {
                sourceUrl
            }
            breadcrumbs {
                text
                url
            }
        }
        title
        content
        excerpt
        featuredImage {
            node{
                altText
                sourceUrl
            }
        }

        extraGallery {
            gallery {
                image {
                    sourceUrl
                    altText
                }
            }
        }
    }

    wpTemplate(slug: {eq: $templateId}) {
		globalInformation {
            contactInfo {
                phone {
                    label
                    text
                }
                address {
                    label
                    text
                }
                email {
                    label
                    text
                }
                workingHours {
                    label
                    text
                }
            }
            contactForm{
                title
                content
                socialLinks{
                    social{
                        type
                        link
                    }
                }
                form{
                    label
                    type
                    errorMessage
                    value
                }
                formCta
            }
            footerExtra {
                description
                copyright
            }
        }
    }
}
`
